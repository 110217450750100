import React from 'react';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';
import { EdgeImg, EdgeBackgroundImg } from './EdgeImg'

class Content161 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, dataSource2, isMobile } = props;
    delete props.dataSource;
    delete props.dataSource2;
    delete props.isMobile;
    return (
      <EdgeBackgroundImg name="2index_hive_big_bg.png" style={{width: '100%', height: '55.36vw'}}>
        <OverPack {...props} {...dataSource.OverPack}>
          <div {...dataSource.page}>
            <QueueAnim
              type="bottom"
              leaveReverse
              key="page"
              delay={1000}
              {...dataSource.titleWrapper}
            >
                {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </QueueAnim>
          </div>
        </OverPack>
        <OverPack playScale={0.3} className="content16-content0">
          {/* <div {...dataSource2.titleWrapper}>

          </div> */}
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={Row}
            delay={2000}
            componentProps={dataSource2.titleWrapper}
          >
            {dataSource2.titleWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={Row}
            delay={2000}
            componentProps={dataSource2.childWrapper}
          >
            {dataSource2.childWrapper.children.map((block, i) => {
              const { children: item, ...blockProps } = block;
              return (
                <Col key={i.toString()} {...blockProps}>
                  <div {...item}>
                    {item.children.map(getChildrenToRender)}
                  </div>
                </Col>
              );
            })}
          </QueueAnim>
        </OverPack>
      </EdgeBackgroundImg>
    );
  }
}

export default Content161;
