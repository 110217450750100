import React from 'react';
import styled, { css, keyframes } from 'styled-components'
import ClientRender from './ClientRender'

const newsUrl = "http://beeplus.com.tw/data/work-data/news.json"
// const newsUrl = "http://localhost:8000/data/work-data/news.json"

const pulse = keyframes`
  0% {
    -webkit-transform: translate3d(30vw, 0, 0);
    transform: translate3d(30vw, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`
const animation = props =>
  css`
    ${pulse} ${props.animationLength} infinite linear normal;
  `
const ScrollDiv = styled.div`
  animation: ${animation};
`

class Banner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newsLink: "https://www.facebook.com/Bee-House-100123864861754",
      remoteTickerChildren: [
        {
          "text": "【2020-02-13】求才啟事徵數位媒體行銷廣告業務",
          "url": "https://www.104.com.tw/job/6eusy?jobsource=company_job"
        },
        {
            "text": "【2020-02-13】求才啟事徵視覺設計師",
            "url": "https://www.104.com.tw/job/6bvqs?jobsource=company_job"
        },
        {
            "text": "【2020-02-13】求才啟事徵數位廣告投放優化師",
            "url": "https://www.104.com.tw/job/6mtv2?jobsource=company_job"
        },
        {
            "text": "【2020-02-13】求才啟事徵數位行銷企劃 ",
            "url": "https://www.104.com.tw/job/6thvm?jobsource=company_job"
        }
      ]
    }
  }

  componentDidMount() {
    fetch(newsUrl)
      .then((response) => {
        return response.json()
      })
      .then((responseJson) => {
        const json = responseJson
        this.setState({
          newsLink: json.url,
          remoteTickerChildren: json.news
        })
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { isMobile, ...currentProps } = this.props;
    const { remoteTickerChildren, newsLink } = this.state;
    const dur = remoteTickerChildren.map(({text}) => text).join().length / 3;

    return (
        <div style={{ position: 'relative'}}>
          <video muted autoplay="autoplay" controls playsInline loop width="100%"
                 src="http://www.beeplus.com.tw/data/beeplus-homepage.mp4"
                 style={{ display: 'block' }}
          ></video>
          <ClientRender>
          {
            !isMobile &&
            <div style={{ position: 'relative',
                          paddingBottom: '4px',
                          paddingTop: '4px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#FCC800',
            }}>
              <div style={{
                border: 'white 2px solid',
                backgroundColor: 'black',
                width: '7vw',
                height: '4vw',
                paddingLeft: '1vw',
                fontSize: '1.5vw',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',}}>
                NEWS
              </div>
              <div style={{
                  border: 'white 2px solid',
                  backgroundColor: 'black',
                  width: '70vw',
                  height: '4vw',
                  paddingLeft: '1vw',
                  fontSize: '1.5vw',
                  color: 'white',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                  }}>

                    <ScrollDiv
                    animationLength={`${dur}s`}
                    style={{
                      display: 'inline-block',
                      width: 'max-content',
                    }}>

                        {
                          remoteTickerChildren.map(({text, url}) =>
                            <a href={url} target="_blank" style={{color: 'white'}}>
                              <div style={{display: 'inline-block', paddingLeft: '1vw'}}>{text}</div>
                            </a>
                          )
                        }
                    </ScrollDiv>
                    {/* </div> */}
              </div>
            </div>
          }
          </ClientRender>
        </div>
    );
  }
}

export default Banner;
