import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Link } from "gatsby"
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../utils';
import ClientRender from './ClientRender'
import { EdgeImg } from './EdgeImg'

class Content420 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile, showMore } = props;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...item}
          componentProps={{ md: item.md, xs: item.xs }}
          className={
            !clearFloatNum
              ? `${item.className || ''} clear-both`.trim()
              : item.className
          }
        >
          {/* <TweenOne
            animation={{
              x: '-=10',
              opacity: 0,
              type: 'from',
              ease: 'easeOutQuad',
            }}
            key="img"
            {...childObj.icon}
          >
            <img src={childObj.icon.children} width="100%" alt="img" />
          </TweenOne> */}
          <div {...childObj.textWrapper}>
            <TweenOne
              key="h2"
              animation={childrenAnim}
              component="h2"
              {...childObj.title}
            >
              {childObj.title.children}
            </TweenOne>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="div"
              {...childObj.content}
            >
              {childObj.content.children}
            </TweenOne>
          </div>
        </TweenOne>
      );
    });
    return (
      <ClientRender>
        <div {...props} {...dataSource.wrapper}>
          <div {...dataSource.page}>
            <div {...dataSource.titleWrapper}>
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </div>
            <OverPack {...dataSource.OverPack}>
              {/* <QueueAnim key="u" type="bottom" style={{ minHeight: '250px' }}> */}
              <TweenOne animation={{rotate: 360, duration: 1000}}>
                {
                  isMobile ? (
                    <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', margin: 'auto' }}><EdgeImg name={'index_brain_all.png'} noBase64></EdgeImg></div>
                  </div>
                    // <div style={{ width: '100%', padding: '0 64px' }}>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_1.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_2.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_3.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_4.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_5.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_6.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_7.png'} noBase64></EdgeImg></div>
                    //   <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'index_hive_8.png'} noBase64></EdgeImg></div>
                    // </div>
                  )
                  : (
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '58%', margin: 'auto', maxWidth: '700px', minWidth: '500px' }}><EdgeImg name={'index_brain_all.png'} noBase64></EdgeImg></div>
                    </div>
                  )
                }
                {/* <Row key="row" {...dataSource.block}>
                  {children}
                </Row> */}
                </TweenOne>
              {/* </QueueAnim> */}
            </OverPack>
            {
            !isMobile && showMore &&
            <div
              style={{ margin: '0 0 1vw 1vw' }}>
              <Link
                to="/solution"
                style={{
                  color: `black`,
                  textDecoration: `none`,
                  fontSize: '2vw',
                  borderBottom: 'black 2px solid',
                }}
              >READ MORE</Link>
            </div>
          }

          </div>
        </div>
      </ClientRender>
    );
  }
}

export default Content420;
