import React, { Fragment } from "react"
import ReactPixel from 'react-facebook-pixel'
import { BackTop } from 'antd';
import { Link } from "gatsby"
import ClientMedia from "../components/ClientMedia"
import Media from "react-media"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { EdgeImg, EdgeBackgroundImg } from '../components/EdgeImg'
import ClientRender from '../components/ClientRender'
/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */

import Nav0 from '../components/Nav0';
import BannerVideo from '../components/BannerVideo';
import Banner2 from '../components/Banner2';
import Banner0 from '../components/Banner0';
import Banner3 from '../components/Banner3';
import Content0 from '../components/Content0';
import Content5 from '../components/Content5';
import Content16 from '../components/Content16';
import Content161 from '../components/Content161';
import Content13 from '../components/Content13';
import Content420 from '../components/Content420';
import Content3 from '../components/Content3';
import Feature8 from '../components/Feature8';
import Feature5 from '../components/Feature5';
import Contact1 from '../components/Contact1';
import Contact0 from '../components/Contact0';
import Footer0 from '../components/Footer0';

import {
  Nav00DataSource,
  Banner20DataSource,
  Banner00DataSource,
  Banner30DataSource,
  Content00DataSource,
  Content50DataSource,
  Content130DataSource,
  Content160DataSource,
  Content1610DataSource,
  Content30DataSource,
  Content4200DataSource,
  Feature80DataSource,
  Feature50DataSource,
  Contact10DataSource,
  Contact00DataSource,
  Footer00DataSource,
} from '../data.source';
import '../less/antMotionStyle.less';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    // enquireScreen((b) => {
    //   this.setState({ isMobile: !!b });
    // });
    this.setState({ isClient: true })
    ReactPixel.init('623365871552504');
    ReactPixel.pageView();
  }

  handleMediaQueryChange = (matches) => {
    console.log(matches)
    // matches will be true or false based on the value for the media query
  }

  render() {
    // if (!this.state.isClient) return null
    // const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    // if (typeof window !== `undefined`) {
    return (
      <Media queries={{
        mobile: "(max-width: 767px)",
        // small: "(max-width: 599px)",
        // medium: "(min-width: 600px) and (max-width: 1199px)",
        // large: "(min-width: 1200px)"
      }}>
        {matches => (
          // return (
          <div
            style={{margin: 'auto', overFlow: 'hidden'}}
            className="templates-wrapper"
            ref={(d) => {
              this.dom = d;
            }}
          >
            <Fragment>
              <SEO></SEO>
              <Nav0
                id="Nav0_0"
                key="Nav0_0"
                dataSource={Nav00DataSource}
                isMobile={matches.mobile}
              />

              <BannerVideo
                id="BannerVideo_0"
                key="BannerVideo_0"
                // dataSource={BannerVideo0DataSource}
                isMobile={matches.mobile}>
                </BannerVideo>
                <ClientRender>
              {
                matches.mobile ? (
                    <div style={{
                        width: '100%', position: 'relative', overFlow: 'hidden', backgroundColor: '#FCC800',
                        // border: 'red 2px solid',

                      }}>
                        <Content16
                          id="Content16_0"
                          key="Content16_0"
                          dataSource={Content160DataSource}
                          isMobile={matches.mobile}
                        />
                        <Content0
                          id="Content0_0"
                          key="Content0_0"
                          dataSource={Content00DataSource}
                          isMobile={matches.mobile}
                        />
                      </div>
                        ) : (
                  <Content161
                    id="Content161_0"
                    key="Content161_0"
                    dataSource={Content1610DataSource}
                    dataSource2={Content00DataSource}
                    isMobile={matches.mobile}
                  />
                )
              }
              {
                matches.mobile && <BackTop />
              }
              </ClientRender>
              {/* <div style={{
                width: '100%', position: 'relative', overFlow: 'hidden', backgroundColor: '#FCC800',
                border: 'red 2px solid',

              }}>
              </div> */}
              <Content5
                id="Content5_0"
                key="Content5_0"
                dataSource={Content50DataSource}
                isMobile={matches.mobile}
                useCarousel={true}
                showMore={true}
              />
              <Content420
                id="Content420_0"
                key="Content420_0"
                dataSource={Content4200DataSource}
                isMobile={matches.mobile}
                showMore={true}
              />
              {/* <Feature8
                id="Feature8_0"
                key="Feature8_0"
                dataSource={Feature80DataSource}
                isMobile={matches.mobile}
                /> */}
              {/*
              <Feature5
                id="Feature5_0"
                key="Feature5_0"
                dataSource={Feature50DataSource}
                isMobile={matches.mobile}
              /> */}
              <Contact1
                id="Contact1_0"
                key="Contact1_0"
                dataSource={Contact10DataSource}
                isMobile={matches.mobile}
              />
              <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={matches.mobile}
              />
            </Fragment>
          </div>)}
        </Media>)
  }
}
