import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';
import { EdgeImg } from './EdgeImg'

class Content16 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <div {...dataSource.page}>
          <QueueAnim
            type="bottom"
            leaveReverse
            key="page"
            delay={[0, 100]}
          >
            {
              isMobile ?
              (<div {...dataSource.titleWrapper}>{dataSource.titleWrapper.children.map(getChildrenToRender)}</div>)
              :
              (<div className="ant-row">
                <div {...dataSource.titleWrapper}>
                  {dataSource.titleWrapper.children.map(getChildrenToRender)}
                </div>
                <div className="ant-col-md-12" style={{padding: '3%'}}>
                  <EdgeImg name={'index_hive_bg.png'}/>
                </div>
              </div>)
            }
            {/* <EdgeImg name={'index_hive_bg.png'} style={{position: 'absolute', top: 0, right: '100%'}}/> */}
          </QueueAnim>
          {/* <QueueAnim
              type="bottom"
              leaveReverse
              key="page"
              delay={[0, 100]}
              {...dataSource.childWrapper}
            >
              {dataSource.childWrapper.children.map(getChildrenToRender)}
            </QueueAnim> */}
          {isMobile && <EdgeImg name="index_hive_bg_mobile.png" style={{margin: '0 -16px'}}></EdgeImg>}
        </div>
      </OverPack>
    );
  }
}

export default Content16;
